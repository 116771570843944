import { useEffect, useState } from "react";
import styled from "styled-components";
import Input from "../../Components/Input/Input";

const INTRO_TEXT =
  "\n\nI took this photo for my YouTube channel, where I do astrophotography from a highly light polluted city (Bortle 9) (https://youtube.com/@GediAstro for the interested).";

const OUTRO_TEXT =
  "\n\nStacked in APP\nProcessed in PI (BXT, NXT, GHS)\nAdjustments in PS (Colors, contrast, SXT)";

const StyledSharePage = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 120px;
`;

const StyledLabel = styled.p`
  display: inline-block;
  margin-right: 20px;
`;

const StyledInput = styled.input`
  width: 75px;
`;

const StyledDataSection = styled.div`
  display: flex;
  gap: 80px;
`;

const StyledDataCol = styled.div`
  flex-basis: 33%;
`;

const StyledTextArea = styled.textarea`
  width: 400px;
  height: 150px;
  font-size: 18px;
  padding: 10px;
  font-family: Helvetica;
`;

const StyledResults = styled.textarea`
  margin-top: 20px;
  width: 600px;
  height: 300px;
`;

const StyledLink = styled.div``;

const filters = [
  { name: "S", time: 300, hours: 0, minutes: 0, total: "" },
  { name: "H", time: 300, hours: 0, minutes: 0, total: "" },
  { name: "O", time: 300, hours: 0, minutes: 0, total: "" },
  { name: "L", time: 180, hours: 0, minutes: 0, total: "" },
  { name: "R", time: 180, hours: 0, minutes: 0, total: "" },
  { name: "G", time: 180, hours: 0, minutes: 0, total: "" },
  { name: "B", time: 180, hours: 0, minutes: 0, total: "" },
];

const SharePage = () => {
  const [filterData, setFilterData] = useState(filters);
  const [resultText, setResultText] = useState(INTRO_TEXT);
  const [description, setDescription] = useState("");
  const [selectedTelescope, setSelectedTelescope] = useState(
    "RedCat51 WIFD-f/4.9 | 250mm"
  );

  useEffect(() => {
    let filtersText = "\n\n";
    let sumHours = 0;
    let sumMinutes = 0;
    filterData.forEach((filter) => {
      if (filter.total !== "") {
        sumHours += filter.hours;
        sumMinutes += filter.minutes;
        filtersText += `${filter.name}: ${filter.total}\n`;
      }
    });
    if (sumHours || sumMinutes) {
      filtersText += `\nTotal: ${sumHours ? `${sumHours}h` : ""}${sumMinutes ? `${sumMinutes}min` : ""}\n`;
    }
    const aquisitionText = `\n\nAquisition: Bortle 9 | ${selectedTelescope.split("-")[1]} | Gain 100`;
    setResultText(
      `${description}${INTRO_TEXT}\n\nGear:\nZWO ASI2600MM Pro | ZWO AM5 | ${selectedTelescope.split("-")[0]} | Optolong LRGB-SHO (3nm)${aquisitionText}${filtersText}${OUTRO_TEXT}`
    );
  }, [description, selectedTelescope, filterData]);

  const copyResults = () => {
    navigator.clipboard.writeText(resultText);
  };

  const updateFilter = (name: string, time: number, evt: any) => {
    const seconds = evt.target.value * time;
    const minutes = seconds / 60;
    const hours = Math.floor(minutes / 60);
    const minutesLeft = minutes - hours * 60;
    const newFilters = filterData.map((filter) => {
      if (filter.name === name) {
        return {
          ...filter,
          hours,
          minutes: minutesLeft,
          total: `${hours ? `${hours}h` : ""}${minutesLeft ? `${minutesLeft}min` : ""}`,
        };
      }
      return filter;
    });
    setFilterData(newFilters);
  };

  return (
    <StyledSharePage>
      <h1>Share information</h1>
      <StyledDataSection>
        <StyledDataCol>
          {filterData.map((filter) => (
            <StyledRow key={filter.name}>
              <StyledLabel>{filter.name}:</StyledLabel>
              <StyledInput
                defaultValue=""
                onChange={(e) => updateFilter(filter.name, filter.time, e)}
              />
            </StyledRow>
          ))}
        </StyledDataCol>
        <StyledDataCol>
          <StyledTextArea onChange={(e) => setDescription(e.target.value)} />
        </StyledDataCol>
        <StyledDataCol>
          <select onChange={(e) => setSelectedTelescope(e.target.value)}>
            <option value="RedCat51 WIFD-f/4.9 | 250mm">RedCat</option>
            <option value="TS115/800-f/5.6 | 630mm">TS</option>
            <option value="Edge HD8-f/7 | 1422mm">Edge</option>
          </select>
        </StyledDataCol>
      </StyledDataSection>
      <StyledResults value={resultText} />
      <StyledLink role="button" onClick={copyResults}>
        Copy
      </StyledLink>
    </StyledSharePage>
  );
};

export default SharePage;

/*
This is my latest photo for my YouTube channel (https://youtube.com/@GediAstro for the interested).

{DESCRIPTION_TEXT}

Gear:
ZWO ASI2600MM Pro | ZWO AM5 | {TELESCOPE_TEXT} | Optolong LRGB-SHO (3nm)

Aquisition:
300" | f/5.6 | Gain 100 | Bortle 9
6h Total exposure time where

L: 4h30m
R: 30m
G: 30m
B: 30m



*/
