import { RefObject, useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";

type Props = {
  className?: string;
  placeholder?: string;
  value?: string;
  inputType?: string;
  required?: boolean;
};

type InputProps = {
  $showerror?: boolean;
  $hasfocus?: boolean;
};

export const InputTypes = {
  TEXT: "TEXT",
  EMAIL: "EMAIL",
  ORG_NR: "ORG_NR",
};

const EMAIL_ERROR = "Felaktig epost";
const TEXT_ERROR = "Fältet får inte vara tomt";
const ORG_ERROR = "Felaktigt organisationsnummer";

const StyledInputContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const StyledInput = styled.input<InputProps>`
  border: 1px solid
    ${(props) => (props.$showerror ? "var(--red)" : "var(--gray)")};
  background-color: var(--white);
  font-size: 1.2rem;
  padding: 10px 20px;
  outline: none;
  &:focus {
    border: 1px solid
      ${(props) => (props.$showerror ? "var(--red)" : "var(--darkGray)")};
  }
`;
const StyledPlaceholder = styled.span<InputProps>`
  position: absolute;
  left: 11px;
  top: 15px;
  pointer-events: none;
  transition: 80ms;
  background-color: var(--white);
  padding: ${(props) => (props.$hasfocus ? "0 10px" : "0")};
  color: ${(props) => (props.$hasfocus ? "var(--black)" : "var(--darkGray)")};
  color: ${(props) => (props.$showerror ? "var(--red)" : "")};
  top: ${(props) => (props.$hasfocus ? "-8px" : "15px")};
  left: ${(props) => (props.$hasfocus ? "5px" : "20px")};
  font-size: ${(props) => (props.$hasfocus ? "0.9rem" : "1.2rem")};
`;

const StyledError = styled.span`
  color: var(--red);
  margin-left: auto;
  margin-top: 4px;
`;

const Input = ({
  className,
  placeholder,
  value,
  inputType = InputTypes.TEXT,
  required,
}: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [hasFocus, setHasFocus] = useState(false);
  const [error, setError] = useState("");

  const isValidEmail = useCallback(
    (email: string) => {
      if (!required) {
        return true;
      }
      if (
        email.length &&
        !String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          )
      ) {
        return false;
      }
      return true;
    },
    [required],
  );

  const isValidField = useCallback(
    (callBackValue: string) => {
      if (!required) {
        return true;
      }
      return !!callBackValue?.length;
    },
    [required],
  );

  useEffect(() => {
    if (value) {
      setHasFocus(true);
    }
    if (inputType === InputTypes.EMAIL) {
      if (!isValidEmail(value as string)) {
        setError(EMAIL_ERROR);
      }
    }
    if (inputType === InputTypes.TEXT) {
      if (!isValidField(value ?? "")) {
        setError(TEXT_ERROR);
      }
    }
  }, [inputType, isValidEmail, isValidField, value]);

  const onClickListener = () => {
    setHasFocus(true);
  };

  const onKeyUpListener = () => {
    if (inputType === InputTypes.EMAIL) {
      if (isValidEmail(inputRef?.current?.value ?? "")) {
        setError("");
      } else {
        setError(EMAIL_ERROR);
      }
    }
    if (inputType === InputTypes.TEXT) {
      if (isValidField(inputRef?.current?.value ?? "")) {
        setError("");
      } else {
        setError(TEXT_ERROR);
      }
    }
  };

  const onBlurListener = () => {
    if (inputRef?.current?.value === "") {
      setHasFocus(false);
    }
  };
  return (
    <StyledInputContainer className={className}>
      {placeholder && (
        <StyledPlaceholder
          $showerror={(error && error.length > 0) as boolean}
          $hasfocus={hasFocus}
        >
          {placeholder}
          {required && "*"}
        </StyledPlaceholder>
      )}
      <StyledInput
        $showerror={(error && error.length > 0) as boolean}
        defaultValue={value}
        ref={inputRef}
        onKeyUp={onKeyUpListener}
        onBlur={onBlurListener}
        onClick={onClickListener}
      />
      {error && <StyledError>{error}</StyledError>}
    </StyledInputContainer>
  );
};

export default Input;
