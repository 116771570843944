import { useState } from "react";
import styled from "styled-components";

type ButtonProps = {
  color: string;
};

type CardProps = {
  selection: string;
};

const StyledPage = styled.div`
  height: 100vh;
  margin: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const StyledSelectButton = styled.button<ButtonProps>`
  display: flex;
  line-height: 0;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  flex-direction: 1;
  max-width: 100px;
  width: 100%;
  height: 100px;
  border-radius: 150px;
  border: none;

  background-color: ${(props) => props.color};
`;

const StyledSelection = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
`;
const StyledCard = styled.div<CardProps>`
  background-color: ${(props) => props.selection};
  width: 100%;
  border-radius: 20px;
  height: 60vh;
`;

const HealthPage = () => {
  const [selection, setSelection] = useState("");

  return (
    <StyledPage>
      <StyledSelection>
        <StyledSelectButton
          color="#dd5144"
          onClick={() => setSelection("#dd5144")}
        >
          😩
        </StyledSelectButton>
        <StyledSelectButton
          color="#ffcd46"
          onClick={() => setSelection("#ffcd46")}
        >
          😶
        </StyledSelectButton>
        <StyledSelectButton
          color="#1da462"
          onClick={() => setSelection("#1da462")}
        >
          😄
        </StyledSelectButton>
      </StyledSelection>
      <StyledCard selection={selection} />
    </StyledPage>
  );
};

export default HealthPage;
